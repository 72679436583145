import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "list-unstyled" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ResourceListItem = _resolveComponent("ResourceListItem")!

  return (_openBlock(), _createElementBlock("ul", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.resources, (resource, index) => {
      return (_openBlock(), _createBlock(_component_ResourceListItem, {
        key: resource.id,
        class: _normalizeClass(["p-6 cursor-pointer", {active: _ctx.currentResource === resource}]),
        "data-test-id": `resource-${resource.id}-list-item`,
        index: index,
        "media-property": "duration",
        resource: resource,
        onClick: ($event: any) => (_ctx.$emit('play-resource', resource))
      }, null, 8, ["class", "data-test-id", "index", "resource", "onClick"]))
    }), 128))
  ]))
}