import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "d-flex flex-column vh-100" }
const _hoisted_2 = { class: "row g-0 align-items-center px-2 py-4 mb-6 border-bottom" }
const _hoisted_3 = { class: "col-1" }
const _hoisted_4 = { class: "ms-6" }
const _hoisted_5 = { class: "col-10 text-center" }
const _hoisted_6 = { class: "d-flex overflow-hidden" }
const _hoisted_7 = {
  key: 0,
  class: "row flex-nowrap w-100"
}
const _hoisted_8 = { class: "col-3 d-flex flex-column" }
const _hoisted_9 = { class: "d-flex align-items-center mb-4 mx-10 pb-8 border-bottom" }
const _hoisted_10 = { class: "ms-auto" }
const _hoisted_11 = { class: "ms-4" }
const _hoisted_12 = { class: "col-9" }
const _hoisted_13 = { class: "ms-8" }
const _hoisted_14 = { class: "border-bottom mt-6 mb-3 pb-3" }
const _hoisted_15 = { class: "text-muted" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Fa = _resolveComponent("Fa")!
  const _component_VideosListScript = _resolveComponent("VideosListScript")!
  const _component_VideoPlayer = _resolveComponent("VideoPlayer")!
  const _component_WaitForResource = _resolveComponent("WaitForResource")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("button", {
          class: "btn btn-white",
          "data-test-id": "btn-back-to-scripts",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.goBack && _ctx.goBack(...args)))
        }, [
          _createVNode(_component_Fa, { icon: "arrow-left" }),
          _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.$t('actions.back')), 1)
        ])
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("h3", null, _toDisplayString(_ctx.$t('templates.script.title', [_ctx.template.name])), 1)
      ])
    ]),
    _createVNode(_component_WaitForResource, { resource: _ctx.template }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_6, [
          (_ctx.currentResource)
            ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                _createElementVNode("div", _hoisted_8, [
                  _createElementVNode("div", _hoisted_9, [
                    _createElementVNode("h4", null, _toDisplayString(_ctx.$t('templates.script.script')), 1),
                    _createElementVNode("div", _hoisted_10, [
                      _createTextVNode(_toDisplayString(_ctx.$t('templates.script.videosCount', [_ctx.template.mediaResources.count()])) + " ", 1),
                      _createElementVNode("span", _hoisted_11, _toDisplayString(_ctx.$t('templates.script.durationMinutes', [_ctx.template.mediaResources.formattedTotalMediaDuration])), 1)
                    ])
                  ]),
                  _createVNode(_component_VideosListScript, {
                    class: "overflow-y-auto scrollbar px-4",
                    "current-resource": _ctx.currentResource,
                    resources: _ctx.template.mediaResources,
                    onPlayResource: _cache[1] || (_cache[1] = ($event: any) => (_ctx.currentResource = $event))
                  }, null, 8, ["current-resource", "resources"])
                ]),
                _createElementVNode("div", _hoisted_12, [
                  _createElementVNode("div", _hoisted_13, [
                    _createVNode(_component_VideoPlayer, {
                      "keep-dimensions-in-theatre": "",
                      "play-on-src-change": "",
                      video: _ctx.currentResource.media.first()
                    }, null, 8, ["video"]),
                    _createElementVNode("h3", _hoisted_14, _toDisplayString(_ctx.currentResource.title), 1),
                    _createElementVNode("p", _hoisted_15, _toDisplayString(_ctx.currentResource.body), 1)
                  ])
                ])
              ]))
            : _createCommentVNode("", true)
        ])
      ]),
      _: 1
    }, 8, ["resource"])
  ]))
}