
import {defineComponent, ref} from 'vue';
import Resource from '@/models/Resource';
import Template from '@/models/Template';
import VideoPlayer from '@/components/common/plyr/VideoPlayer.vue';
import VideosListScript from '@/components/templates/VideosListScript.vue';
import {useRoute} from 'vue-router';

export default defineComponent({
    components: {
        VideoPlayer,
        VideosListScript,
    },
    props: {
        slug: {
            type: String,
            required: true,
        },
    },
    setup(props) {
        const {resourceId} = useRoute().query;

        return {
            currentResource: ref<Resource>(),
            initialResource: Number(resourceId || 0),
            template: ref<Template>(new Template({
                slug: props.slug,
            })),
        };
    },
    async created() {
        await this.template.fetch();

        if (!this.template.mediaResources) {
            return;
        }

        this.currentResource = this.initialResource
            ? this.template.mediaResources.firstWhere('id', this.initialResource)
            : this.template.mediaResources.first();
    },
    methods: {
        goBack() {
            this.$router.go(-1);
        },
    },
});
