
import {PropType, defineComponent} from 'vue';
import Resource, {Resources} from '@/models/Resource';
import ResourceListItem from '@/components/resources/ResourceListItem.vue';

export default defineComponent({
    components: {
        ResourceListItem,
    },
    props: {
        currentResource: {
            type: Object as PropType<Resource>,
            required: true,
        },
        resources: {
            type: Object as PropType<Resources>,
            required: true,
        },
    },
    emits: [
        'play-resource',
    ],
});
